import useApplicantStore from '../store/applicant/useApplicantStore';
import { NO, YES } from '../store/applicant/YesOrNo';
import useDashboardStore from '../store/dashboardStore';
import { WorkflowPage } from './workflowPage';

export const applicantWorkflow: WorkflowPage[] = [
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/residence-type`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/residence-details`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/residence-duration`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/previous-residence`;
    },
    condition() {
      const {
        computed: { isCurrentResidenceLessThan2Years },
      } = useApplicantStore.getState();

      return isCurrentResidenceLessThan2Years;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/employment-status`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/employment-type`;
    },
    condition() {
      const { isCurrentlyEmployed } = useApplicantStore.getState();
      return isCurrentlyEmployed === YES;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/unemployment-type`;
    },
    condition() {
      const { isCurrentlyEmployed } = useApplicantStore.getState();
      return isCurrentlyEmployed === NO;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/employment-details`;
    },
    condition() {
      const { isCurrentlyEmployed } = useApplicantStore.getState();
      return isCurrentlyEmployed === YES;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/unemployment-details`;
    },
    condition() {
      const { isCurrentlyEmployed } = useApplicantStore.getState();
      return isCurrentlyEmployed === NO;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/employment-duration`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/previous-employment`;
    },
    condition() {
      const {
        computed: { isCurrentIncomeLessThan1Year },
      } = useApplicantStore.getState();

      return isCurrentIncomeLessThan1Year;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/additional-income`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/income-details`;
    },
    condition() {
      const { hasAdditionalIncome } = useApplicantStore.getState();
      return hasAdditionalIncome === YES;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/add-coapplicant`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/coapp-personal-relationship`;
    },
    condition() {
      const { hasCoApplicant } = useApplicantStore.getState();
      return hasCoApplicant === YES;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/review-applicant`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/submit-application`;
    },
  },
  {
    get path() {
      const { vehicle } = useDashboardStore.getState();
      return `/applicant/${vehicle?.id}/application-success`;
    },
  },
];
