import { isString, pick } from 'lodash';
import { devtools, persist } from 'zustand/middleware';

import useApplicantStore from '../applicant/useApplicantStore';
import { NO } from '../applicant/YesOrNo';
import { create, storeDevToolOptions } from '../storeManager';
import { CoapplicantState } from './CoapplicantState';
import { RelationshipEnum } from './enums/RelationshipEnum';

const useCoapplicantStore = create({ hmrPersist: 'coapplicantStore' })(
  devtools(
    persist<CoapplicantState>(
      (set, get) => ({
        coapplicantId: null,
        coapplicantRelationship: null,

        hasLoadedCoapplicant: false,

        isLoadingCoapplicant: false,
        isSyncingCoapplicant: false,

        syncingCoapplicantFailedMessage: null,

        computed: {
          get isCoapplicantPersisted() {
            const { coapplicantId } = get();
            return isString(coapplicantId) && coapplicantId.length !== 0;
          },
        },

        loadCoapplicant() {
          const { hasCoApplicant } = useApplicantStore.getState();
          const { hasLoadedCoapplicant, isLoadingCoapplicant } = get();

          if (
            hasCoApplicant === NO ||
            hasLoadedCoapplicant ||
            isLoadingCoapplicant
          ) {
            return Promise.resolve(null);
          }

          set({ isLoadingCoapplicant: true });

          return Promise.resolve().finally(() =>
            set({ hasLoadedCoapplicant: true, isLoadingCoapplicant: false }),
          );
        },

        setCoapplicantRelationship(value: RelationshipEnum | null) {
          set({ coapplicantRelationship: value });
        },

        syncCoapplicant() {
          // Sync the coapplicant data with the API
          set({
            isSyncingCoapplicant: true,
            syncingCoapplicantFailedMessage: null,
          });

          return Promise.resolve(true).finally(() =>
            set({ isSyncingCoapplicant: false }),
          );
        },
      }),
      {
        name: 'consumer_portal:coapplicant',
        partialize(state: CoapplicantState) {
          const keysToPersist: string[] = ['coapplicantRelationship'];

          return pick(state, keysToPersist) as CoapplicantState;
        },
      },
    ),
    storeDevToolOptions('coapplicantStore'),
  ),
);

export default useCoapplicantStore;
